import React, { useMemo, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import _startCase from 'lodash/startCase';
import { kyruusFormatHTMLMessage } from '@kyruus/intl';
import CancelCircle from '@kyruus/react-ikons/CancelCircle';

import { DISTANCE_ANY } from '../facet-panel/facet-list/location-facet';
import {
  StyledChipsContainer,
  RemoveFilterChipButton,
  FacetChip
} from '../styles';
import { useFocusManager } from './hooks/useFocusManager';
import { getTabIndex } from './utils';

const messages = defineMessages({
  removeFilterChipLabel: {
    id: 'facet_chips.remove_filter',
    description: 'Accessibility label for removing a search facet chip',
    defaultMessage: 'Remove filter'
  },
  locationFilterChipLabel: {
    id: 'facet_chips.location',
    description: 'Summary of search location information',
    defaultMessage:
      'Within {distance, plural, =Any {any miles} one {# mile} other {# miles}} of {location}'
  }
});

const AppliedChipsBase = ({
  filter,
  getUpdatedSearch,
  history,
  intl,
  onFocus,
  tabIndex
}) => {
  const facetLabelMessageDescriptor = {
    id: `field.name.${filter.field}`,
    defaultMessage: _startCase(filter.field),
    description: 'The name displayed above the facet'
  };
  const baseFacetMessageDescriptor = {
    id: `field.value.${filter.field}.${filter.term.value}`,
    defaultMessage: `${filter.term.value}`,
    description: 'Facet for provider search'
  };
  const chipLabel = `${kyruusFormatHTMLMessage(
    intl,
    facetLabelMessageDescriptor
  )}: ${kyruusFormatHTMLMessage(intl, baseFacetMessageDescriptor)}`;
  const removeChipLabel = kyruusFormatHTMLMessage(
    intl,
    messages.removeFilterChipLabel
  );
  return (
    <FacetChip
      color="primary"
      role="group"
      tabIndex={-1}
      data-testid={`filter-chip-${filter.field}-${filter.term.value}`}
      label={
        <span
          aria-hidden
          id={`filter-chip-${filter.field}-${filter.term.value}`}
        >
          {chipLabel}
        </span>
      }
      onDelete={() =>
        history.push(
          getUpdatedSearch([
            {
              action: 'delete_key_value',
              key: 'filter',
              value: `${filter.field}:${filter.term.value}`
            }
          ])
        )
      }
      deleteIcon={
        <RemoveFilterChipButton
          mode="primary"
          role="button"
          onFocus={onFocus}
          tabIndex={tabIndex}
          aria-describedby={`filter-chip-${filter.field}-${filter.term.value}`}
          aria-label={removeChipLabel}
        >
          <CancelCircle size="1.6em" aria-hidden title={removeChipLabel} />
        </RemoveFilterChipButton>
      }
    />
  );
};
const LocationChip = ({
  getUpdatedSearch,
  history,
  intl,
  onFocus,
  searchSummary,
  tabIndex
}) => {
  const chipLabel = kyruusFormatHTMLMessage(
    intl,
    messages.locationFilterChipLabel,
    {
      location: searchSummary.display_location ?? searchSummary.location,
      distance: searchSummary.distance || DISTANCE_ANY
    }
  );
  const removeChipLabel = kyruusFormatHTMLMessage(
    intl,
    messages.removeFilterChipLabel
  );
  return (
    <FacetChip
      color="primary"
      role="group"
      tabIndex={-1}
      data-testid="filter-chip-location"
      label={
        <span aria-hidden id="filter-chip-location-content">
          {chipLabel}
        </span>
      }
      onDelete={() =>
        history.push(
          getUpdatedSearch([
            { action: 'delete_key', key: 'display_location' },
            { action: 'delete_key', key: 'location' },
            { action: 'delete_key', key: 'distance' }
          ])
        )
      }
      deleteIcon={
        <RemoveFilterChipButton
          onFocus={onFocus}
          tabIndex={tabIndex}
          mode="primary"
          role="button"
          aria-describedby="filter-chip-location-content"
          aria-label={removeChipLabel}
        >
          <CancelCircle size="1.6em" aria-hidden title={removeChipLabel} />
        </RemoveFilterChipButton>
      }
    />
  );
};

const AppliedChips = withRouter(injectIntl(AppliedChipsBase));
const LocationAppliedChip = withRouter(injectIntl(LocationChip));

const isHiddenFacet = (facet) =>
  [
    'location',
    'locations.primary_marketable_location_id',
    'locations.associated_marketable_location_ids',
    'provider.id'
  ].includes(facet.field);

const hasTermsAvailable = (facet) => !!facet?.terms?.length;

const shouldShowFacet = (facet) =>
  !isHiddenFacet(facet) && hasTermsAvailable(facet);

const FacetChips = ({ facets, getUpdatedSearch, searchSummary }) => {
  const [activeChip, setActiveChip] = useState(0);
  const { 'aria-orientation': ariaOrientation, onKeyDown } = useFocusManager({
    orientation: 'horizontal',
    itemElementRole: 'button'
  });

  const appliedFilters = useMemo(
    () =>
      facets.filter(shouldShowFacet).reduce((acc, { field, terms }) => {
        terms
          .filter((term) => term.applied)
          .forEach((term) => acc.push({ field, term }));
        return acc;
      }, []),
    [facets]
  );

  // reset active chip
  useEffect(() => {
    setActiveChip(0);
  }, [appliedFilters.length]);

  const showLocationChip = searchSummary.location;

  const allFacetChips = appliedFilters.map((filter, idx) => {
    const index = showLocationChip ? idx + 1 : idx;
    const selected = activeChip === index;
    const tabIndex = getTabIndex(activeChip, selected, index);
    return (
      <AppliedChips
        filter={filter}
        getUpdatedSearch={getUpdatedSearch}
        key={filter.term.filter_param}
        tabIndex={tabIndex}
        onFocus={() => setActiveChip(index)}
      />
    );
  });

  const locationChipIndex = 0;
  const locationChipSelected = activeChip === locationChipIndex;
  const locationChipTabIndex = getTabIndex(activeChip, locationChipSelected, 0);
  return (
    <StyledChipsContainer
      role="toolbar"
      aria-orientation={ariaOrientation}
      onKeyDown={onKeyDown}
      tabIndex={-1}
      onBlur={() => setActiveChip(0)}
    >
      {showLocationChip && (
        <LocationAppliedChip
          searchSummary={searchSummary}
          getUpdatedSearch={getUpdatedSearch}
          tabIndex={locationChipTabIndex}
          onFocus={() => setActiveChip(locationChipIndex)}
        />
      )}
      {allFacetChips}
    </StyledChipsContainer>
  );
};

export { FacetChips, shouldShowFacet };
